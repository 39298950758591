import React, {useContext, useMemo, useState} from 'react'
import LazyComponent from './LazyComponent'

export type ModalContextProps<T> = {
  show: (id: string, model?: T) => void
  hide: (id: string) => void
  getModel: (id: string) => T | undefined
}

export type Modal<T = undefined> = {
  id: string
  isOpened: boolean
  model?: T
}

export type ModalMap = {
  [id: string]: Modal<any>
}

const ModalContext = React.createContext<ModalContextProps<any>>({
  show: (id, options) => {},
  hide: (id) => {},
  getModel: (id) => {
    return undefined
  },
})

export type ModalProviderProps = {}

export const useModal = <T,>() => {
  return useContext<ModalContextProps<T>>(ModalContext)
}

const ModalProvider = (props: React.PropsWithChildren<ModalProviderProps>) => {
  const [modals, setModals] = useState<ModalMap>({})
  const openedModal = useMemo(
    () => Object.keys(modals).filter((id) => modals[id].isOpened),
    [modals]
  )

  const show = <T,>(id: string, model?: T) => {
    setModals({
      ...modals,
      [id]: {
        id: id,
        isOpened: true,
        model: model,
      },
    })
  }
  const hide = (id: string) => {
    setModals({
      ...modals,
      [id]: {
        id: id,
        isOpened: false,
        model: undefined,
      },
    })
  }

  const getModel = <T,>(id: string): T | undefined => {
    return modals[id]?.model
  }

  const state = {
    show,
    hide,
    getModel,
  }

  return (
    <ModalContext.Provider value={state}>
      {openedModal.map((id) => (
        <LazyComponent key={id} path={id} {...modals[id]} />
      ))}
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
