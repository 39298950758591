import axios from 'axios'
import {AuthModel} from './_models'

const API_URL = process.env.REACT_APP_AUTH_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REFRESH_URL = `${API_URL}/auth/refresh`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`

// Server should return AuthModel
export function login(login: string, password: string) {
  debugger
  return axios.post<AuthModel>(LOGIN_URL, {
    login,
    password,
  })
}

export function refreshToken(token: AuthModel) {
  return axios.post<AuthModel>(REFRESH_URL, token)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string): any {
  return {
    data: {
      email: '',
      first_name: '',
      last_name: '',
      id: 1,
      username: '',
      password: '',
    },
  }
  //
  // return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
  //   api_token: token,
  // })
}
