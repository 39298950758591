import {Dialog} from '@progress/kendo-react-dialogs'
import React, {createContext, FC, ReactNode, useContext, useState} from 'react'
import {WithChildren} from '../../_metronic/helpers'

export type CmsModalOptions = {
  title?: string
  context?: any
  content?: ReactNode
}

export type CmsModalState = {
  isOpen: boolean
  options: CmsModalOptions
}

export type CmsModalContextProps = {
  state: CmsModalState
  show: (options: Partial<CmsModalOptions>) => void
  hide: () => void
}

const defaultModalOptions: CmsModalOptions = {}

const CmsModalContext = createContext<CmsModalContextProps>({
  state: {
    isOpen: false,
    options: defaultModalOptions,
  },
  show: (options) => {},
  hide: () => {},
})

const CmsModalProvider: FC<WithChildren> = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState(defaultModalOptions)

  const show = (newOptions: Partial<CmsModalOptions>) => {
    setOptions({...options, ...newOptions})
    setIsOpen(true)
  }

  const hide = () => {
    setIsOpen(false)
  }

  const modalContextProps: CmsModalContextProps = {
    state: {
      isOpen,
      options,
    },
    show,
    hide,
  }

  return (
    <CmsModalContext.Provider value={modalContextProps}>
      <Modal />
      {children}
    </CmsModalContext.Provider>
  )
}

const Modal = () => {
  const {
    state: {
      isOpen,
      options: {title, content},
    },
    hide,
  } = useContext(CmsModalContext)

  return (
    <>
      {isOpen && (
        <Dialog title={title} onClose={hide}>
          {content}
        </Dialog>
      )}
    </>
  )
}

const useCmsModal = () => useContext(CmsModalContext)

export {CmsModalProvider, useCmsModal}
