import React, {lazy, Suspense} from 'react'

type LazyComponentProps = {
  path: string
}

const LazyComponent = ({path, ...rest}: LazyComponentProps) => {
  const Component = lazy(() => import(`../../../app/${path}`))
  console.log('LazyComponent', rest)
  return <Suspense fallback={null}>{path ? <Component {...rest} /> : null}</Suspense>
}

export default LazyComponent
