/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/*<SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<SidebarMenuItemWithSub*/}
      {/*  to='/crafted/pages'*/}
      {/*  title='Pages'*/}
      {/*  fontIcon='bi-archive'*/}
      {/*  icon='element-plus'*/}
      {/*>*/}
      {/*  <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
      {/*    <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />*/}
      {/*    <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/profile/campaigns'*/}
      {/*      title='Campaigns'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/profile/documents'*/}
      {/*      title='Documents'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/profile/connections'*/}
      {/*      title='Connections'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*  </SidebarMenuItemWithSub>*/}

      {/*  <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/wizards/horizontal'*/}
      {/*      title='Horizontal'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
      {/*  </SidebarMenuItemWithSub>*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItemWithSub*/}
      {/*  to='/crafted/accounts'*/}
      {/*  title='Accounts'*/}
      {/*  icon='profile-circle'*/}
      {/*  fontIcon='bi-person'*/}
      {/*>*/}
      {/*  <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>*/}
      {/*  <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItemWithSub*/}
      {/*  to='/crafted/widgets'*/}
      {/*  title='Widgets'*/}
      {/*  icon='element-7'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*>*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/cms'
        title='CMS'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/cms/resources' title='Ресурсы' hasBullet={true} />
        <SidebarMenuItem to='/apps/cms/resource-types' title='Типы ресурсов' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/commerce'
        title='Каталог'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/commerce/orders' title='Заказы' hasBullet={true} />
        <SidebarMenuItem to='/apps/commerce/products' title='Продукты' hasBullet={true} />
        <SidebarMenuItem to='/apps/commerce/categories' title='Категории' hasBullet={true} />
        <SidebarMenuItem to='/apps/commerce/collections' title='Коллекции' hasBullet={true} />
        <SidebarMenuItem
          to='/apps/commerce/collection-sets'
          title='Категории коллекций'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/journal'
        title='Журнал'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/journal/posts' title='Статьи' hasBullet={true} />
        <SidebarMenuItem to='/apps/journal/categories' title='Категории' hasBullet={true} />
        <SidebarMenuItem to='/apps/journal/blogs' title='Блоги' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/ads'
        title='Реклама'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/ads/campaigns' title='Рекламные компании' hasBullet={true} />
        <SidebarMenuItem to='/apps/ads/advertisers' title='Рекламодатели' hasBullet={true} />
        <SidebarMenuItem to='/apps/ads/placements' title='Размещения' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/*<SidebarMenuItemWithSub*/}
      {/*  to='/apps/chat'*/}
      {/*  title='Chat'*/}
      {/*  fontIcon='bi-chat-left'*/}
      {/*  icon='message-text-2'*/}
      {/*>*/}
      {/*  <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItem*/}
      {/*  to='/apps/user-management/users'*/}
      {/*  icon='abstract-28'*/}
      {/*  title='User management'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTIcon iconName='code' className='fs-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </>
  )
}

export {SidebarMenuMain}
