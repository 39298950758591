import React, {useEffect, useState} from 'react'
import axios, {AxiosStatic} from 'axios'
import {useAuth} from './Auth'
import {AuthModel} from './_models'
import {NavigateFunction, useNavigate} from 'react-router-dom'
import {refreshToken} from './_requests'
const AxiosInit = ({children}: React.PropsWithChildren<any>) => {
  const {auth, saveAuth, logout} = useAuth()
  const navigate = useNavigate()

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    console.log('AxiosInit ... init')
    axios.defaults.headers.Accept = 'application/json'

    const requestInterceptor = setupRequest(axios, auth)
    const responseInterceptor = setupResponse(axios, auth, navigate)

    setInitialized(true)

    return () => {
      console.log('AxiosInit ... cleanup')
      axios.interceptors.request.eject(requestInterceptor)
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [auth])

  const setupRequest = (axios: AxiosStatic, auth: AuthModel | undefined): number => {
    return axios.interceptors.request.use(
      (value) => {
        if (auth && auth.accessToken) {
          value.headers.Authorization = `Bearer ${auth.accessToken}`
        }
        return value
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const setupResponse = (
    axios: AxiosStatic,
    auth: AuthModel | undefined,
    navigate: NavigateFunction
  ): number => {
    const onRejected = async (error) => {
      if (!auth) {
        logout()
        navigate('/auth')
        return Promise.reject(error)
      }

      const originalRequest = error.config

      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        try {
          const {data} = await refreshToken(auth)
          if (!data) {
            console.log('axios invalid refresh error ', auth)
            logout()
            navigate('/auth')
            return Promise.reject(error)
          }
          saveAuth(data)
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${data.accessToken}`
          return axios(originalRequest)
        } catch (error) {
          console.log('axios invalid refresh exception ', error)
          logout()
          navigate('/auth')
          return Promise.reject(error)
        }
      }

      return Promise.reject(error)
    }

    return axios.interceptors.response.use((response) => response, onRejected)
  }

  return initialized && children
}

export default AxiosInit
