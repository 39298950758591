import {Suspense, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import {CmsModalProvider} from './components/CmsModal'
import ModalProvider from './components/modal/ModalProvider'
import AxiosInit from './modules/auth/core/AxiosInit'

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  if (!isLoaded) {
    setIsLoaded(true)
  }
  console.log('App.tsx')
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <AxiosInit>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <ModalProvider>
                <CmsModalProvider>
                  <AuthInit>
                    <Outlet />
                    <MasterInit />
                  </AuthInit>
                </CmsModalProvider>
              </ModalProvider>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </AxiosInit>
    </Suspense>
  )
}

export {App}
